import { inject, Injectable, signal } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { Platform } from '@ionic/angular/standalone';
import { ONE_ON_ONE_ADMIN_MENUS } from '@pixels/client/pages/admin/admin.model';
import { AppInfo } from '@pixels/client/pixels/app-info';
import { AppKeyChristian } from '@pixels/universal/model/apps/app-types';
import { ChristianProjectMeta } from '@pixels/universal/model/apps/project-meta/christian-project-meta';

@Injectable({ providedIn: 'root' })
export class ChristianAppInfo extends AppInfo {
  public readonly appType = AppKeyChristian;
  public readonly serviceName = _('@크리스천_친구_만들기@');
  public readonly appStoreUrl = signal(ChristianProjectMeta.appStoreUrl ?? '');
  public readonly playStoreUrl = signal(`https://play.google.com/store/apps/details?id=${ChristianProjectMeta.appId}`);

  public override readonly admobBannerVideoChatAppId = (inject(Platform).is('ios') ? ChristianProjectMeta.ios?.admobBannerVideoChatAppId : ChristianProjectMeta.android?.admobBannerVideoChatAppId) ?? '';
  public override readonly loginTitle = _('@크리스천_친구_만들기@');
  public override readonly loginDescription = _('@진실한_크리스천_친구를_만들어보아요@');
  public override readonly loginAppIconUrl = 'https://cdn.pixelteam.io/pixels/app-icons/christian-app-icon.svg';
  public override readonly bannerPlaceHolder = _('@크리스천_친구_만들기_채팅_소개팅@');
  public override readonly adminMenus = ONE_ON_ONE_ADMIN_MENUS;
}
