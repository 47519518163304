import { AppKeyChristian } from '@pixels/universal/model/apps/app-types';
import { ProjectMeta } from '@pixels/universal/model/apps/project-meta.model';
import { Phase } from '@pixels/universal/model/phase-model';

const FOLDER_NAME = 'christian-client';
const PROD_WEB_PUSH_KEY = 'BHBXd12NFD1WzcG1DmRom67bUhxOGm8xd2oOENEt68VAZjIZkDMzHc4jSijbO3y490HIwEYtLjHEi_CYyD5JkQo';
const DEV_WEB_PUSH_KEY = 'BNsMk7rgsVnwxW0PzqVCU9fJdXXOcMkHRBxymeI1PDVmL6wuEXUrV1pNKQwH3BSJBKlq_tFVP9knekuvrE2Vccw';
const PROD_AUTH_CALLBACK_URL = 'https://pixels-christian.firebaseapp.com/__/auth/handler';
const DEV_AUTH_CALLBACK_URL = 'https://pixels-christian-dev.firebaseapp.com/__/auth/handler';
const ProdFirebaseConfig = {
  apiKey: 'AIzaSyDO5czrGEjZ7tgPxGn0gc5X2hrf7Cz-mTo',
  authDomain: 'pixels-christian.firebaseapp.com',
  projectId: 'pixels-christian',
  storageBucket: 'pixels-christian.appspot.com',
  messagingSenderId: '932290548951',
  appId: '1:932290548951:web:db897b76b8ab6bc41e0c92',
  measurementId: 'G-B0TGKJG4EW'
};
const DevFirebaseConfig = {
  apiKey: 'AIzaSyBF8YJuS6BmNhhBUPxkWn91MU-NEts0wmo',
  authDomain: 'pixels-christian-dev.firebaseapp.com',
  projectId: 'pixels-christian-dev',
  storageBucket: 'pixels-christian-dev.appspot.com',
  messagingSenderId: '597302675222',
  appId: '1:597302675222:web:931e28dfdbf3692e09f237',
  measurementId: 'G-Y2GY1Q58X0'
};
const PROD_TALK_PLUS_APP_ID = 'c13d9182-a098-4ba6-ad67-02b21e3b442f';
const DEV_TALK_PLUS_APP_ID = '618a8b13-f9f9-460e-abea-aaf7728569e4';

export const ChristianProjectMeta: ProjectMeta = {
  id: AppKeyChristian,
  mainPrimaryColor: '#7299FF',
  folderName: FOLDER_NAME,
  publicPath: `dist/apps/${FOLDER_NAME}/browser`,
  supportPhases: [Phase.prod, Phase.dev],
  port: 8115,
  apiName: 'christian-api',
  apiPort: 5015,
  appId: 'io.pixelteam.christian',
  appStoreUrl: 'https://apps.apple.com/app/id6502037995',
  appName: '크리스천 친구',
  appNameEn: 'Christians Friends',
  appSchemeName: 'Christian',
  testTargetUrl: 'https://test-christian.pixelteam.io',
  devTargetUrl: 'https://dev-christian.pixelteam.io',
  prodApi: 'https://christian-api.pixelteam.io',
  devApi: 'https://dev-christian-api.pixelteam.io',
  firebaseId: 'pixels-christian',
  iosAppId: '1:597302675222:ios:a9de45264a24baf509f237',
  androidAppId: '1:597302675222:android:66443c9365966c7209f237',
  testHostname: 'test-christian',
  clientSentryDsn: 'https://53a4cb49ef794d688f98380e07f7a597@o190729.ingest.us.sentry.io/4507164933947392',
  prod: {
    deployPath: '/api/v1/project/9070/stage/10103/scenario/11292/deploy',
    targets: ['prod-03'],
    port: 8500,
    androidAppId: '1:932290548951:android:3baf16dd478609d71e0c92',
    iosAppId: '1:932290548951:ios:0c0e86c71a7c8fd71e0c92',
    iosEncodingAppId: 'app-1-932290548951-ios-0c0e86c71a7c8fd71e0c92',
    webAppId: '1:932290548951:web:db897b76b8ab6bc41e0c92',
    kakaoAppKey: '4cab61cc37fa40d0a23185624d3cc226',
    googleReversedId: 'com.googleusercontent.apps.932290548951-2sl5erndlqsoelm1st7ohpmq39jj8m7a',
    webPushKey: PROD_WEB_PUSH_KEY,
    firebaseConfig: ProdFirebaseConfig,
    talkPlusAppId: PROD_TALK_PLUS_APP_ID,
    apiUrl: 'https://christian-api.pixelteam.io',
  },
  dev: {
    deployPath: '/api/v1/project/9070/stage/10104/scenario/11279/deploy',
    targets: ['dev-01'],
    port: 8501,
    androidAppId: '1:597302675222:android:66443c9365966c7209f237',
    iosAppId: '1:597302675222:ios:a9de45264a24baf509f237',
    iosEncodingAppId: 'app-1-597302675222-ios-a9de45264a24baf509f237',
    webAppId: '1:597302675222:web:931e28dfdbf3692e09f237',
    kakaoAppKey: '5f8bf6c9e8e9fc4a558cd168d8039d7e',
    googleReversedId: 'com.googleusercontent.apps.597302675222-5239793h41cmnbpgafh7rhr0lk9lifer',
    webPushKey: DEV_WEB_PUSH_KEY,
    firebaseConfig: DevFirebaseConfig,
    talkPlusAppId: DEV_TALK_PLUS_APP_ID,
    apiUrl: 'https://dev-christian-api.pixelteam.io',
  },
  test: {
    androidAppId: '1:597302675222:android:31ac657f6f3e081f09f237',
    iosAppId: '1:597302675222:ios:049f9849bd1cce3209f237',
    iosEncodingAppId: 'app-1-597302675222-ios-049f9849bd1cce3209f237',
    kakaoAppKey: '5f8bf6c9e8e9fc4a558cd168d8039d7e',
    googleReversedId: 'com.googleusercontent.apps.597302675222-1ucbs1tphklrk72n84f36f0l5te2orv1',
    webPushKey: DEV_WEB_PUSH_KEY,
    firebaseConfig: DevFirebaseConfig,
    talkPlusAppId: DEV_TALK_PLUS_APP_ID,
    apiUrl: 'https://test-christian-api.pixelteam.io',
  },
  android: {
    admobAppId: 'ca-app-pub-4000507444081320~5201483808',
    admobBannerVideoChatAppId: 'ca-app-pub-4000507444081320/7433444215',
  },
  ios: {},
  NSCameraUsageDescription: '카메라로 찍은 사진을 메시지에 첨부 또는 프로필 사진으로 등록할 수 있습니다.',
  NSPhotoLibraryAddUsageDescription: '회원님이 크리스천 친구 만들기에 사진을 저장에 대한 액세스 권한을 사용합니다.',
  NSPhotoLibraryUsageDescription: '앨범에 있는 사진과 GIF를 메시지에 첨부 또는 프로필 사진으로 등록할 수 있습니다.',
  NSCameraUsageDescriptionEn: 'You can attach a photo taken with your camera to a message or list it as your profile picture.',
  NSPhotoLibraryAddUsageDescriptionEn: 'You use access to save photos to make Christian friends.',
  NSPhotoLibraryUsageDescriptionEn: 'You can use photos and GIFs from your albums as attachments to messages or as your profile picture.',
  consoleLogo: `
 ██████╗██╗  ██╗██████╗ ██╗███████╗████████╗██╗ █████╗ ███╗   ██╗
██╔════╝██║  ██║██╔══██╗██║██╔════╝╚══██╔══╝██║██╔══██╗████╗  ██║
██║     ███████║██████╔╝██║███████╗   ██║   ██║███████║██╔██╗ ██║
██║     ██╔══██║██╔══██╗██║╚════██║   ██║   ██║██╔══██║██║╚██╗██║
╚██████╗██║  ██║██║  ██║██║███████║   ██║   ██║██║  ██║██║ ╚████║
 ╚═════╝╚═╝  ╚═╝╚═╝  ╚═╝╚═╝╚══════╝   ╚═╝   ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝
`
};
